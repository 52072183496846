import { PrescriptionsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    prescriptions: (state: PrescriptionsState) => state.prescriptions,
    prescription: (state: PrescriptionsState) => (prescriptionId: number) => {
        const items = state.prescriptions.filter((prescription) => prescription.id === prescriptionId);
        if (items.length > 0) {
            return { ...items[0] };
        }
    },
};

const { read } = getStoreAccessors<PrescriptionsState, State>('');

export const readPrescriptions = read(getters.prescriptions);
export const readPrescription = read(getters.prescription);
