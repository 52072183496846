import { MedicationsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    medications: (state: MedicationsState) => state.medications,
    medication: (state: MedicationsState) => (medicationId: number) => {
        const items = state.medications.filter((medication) => medication.id === medicationId);
        if (items.length > 0) {
            return { ...items[0] };
        }
    },
};

const { read } = getStoreAccessors<MedicationsState, State>('');

export const readMedications = read(getters.medications);
export const readMedication = read(getters.medication);
