import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { ProceduresState } from './state';

const defaultState: ProceduresState = {
  procedures: [],
};

export const proceduresModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
