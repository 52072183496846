import { ProceduresState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    procedures: (state: ProceduresState) => state.procedures,
};

const { read } = getStoreAccessors<ProceduresState, State>('');

export const readProcedures = read(getters.procedures);
