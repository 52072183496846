import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { mainModule } from './main';
import { State } from './state';
import { adminModule } from './admin';
import { patientsModule } from './patients';
import { medicationsModule } from './medications';
import { prescriptionsModule } from './prescriptions';
import { proceduresModule } from './procedures';

Vue.use(Vuex);

const storeOptions: StoreOptions<State> = {
  modules: {
    main: mainModule,
    admin: adminModule,
    patients: patientsModule,
    prescriptions: prescriptionsModule,
    procedures: proceduresModule,
    medications: medicationsModule,
  },
};

export const store = new Vuex.Store<State>(storeOptions);

export default store;
