import { IPatient } from '@/interfaces';
import { PatientsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setPatients(state: PatientsState, payload: IPatient[]) {
        state.patients = payload;
    },
};

const { commit } = getStoreAccessors<PatientsState, State>('');

export const commitSetPatients = commit(mutations.setPatients);
