import { IMedication } from '@/interfaces';
import { MedicationsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setMedications(state: MedicationsState, payload: IMedication[]) {
        state.medications = payload;
    },
};

const { commit } = getStoreAccessors<MedicationsState, State>('');

export const commitSetMedications = commit(mutations.setMedications);
