import { IProcedure } from '@/interfaces';
import { ProceduresState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setProcedures(state: ProceduresState, payload: IProcedure[]) {
        state.procedures = payload;
    },
};

const { commit } = getStoreAccessors<ProceduresState, State>('');

export const commitSetProcedures = commit(mutations.setProcedures);
