import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { ProceduresState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetProcedures } from './mutations';
import { dispatchCheckApiError } from '../main/actions';

type MainContext = ActionContext<ProceduresState, State>;

export const actions = {
    async actionGetProcedures(context: MainContext, patientId: number) {
        try {
            const response = await api.getProcedures(context.rootState.main.token, patientId);
            if (response) {
                commitSetProcedures(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<ProceduresState, State>('');

export const dispatchGetProcedures = dispatch(actions.actionGetProcedures);
