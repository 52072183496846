import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { MedicationsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetMedications } from './mutations';
import { dispatchCheckApiError } from '../main/actions';

type MainContext = ActionContext<MedicationsState, State>;

export const actions = {
    async actionGetMedications(context: MainContext) {
        try {
            const response = await api.getMedications(context.rootState.main.token);
            if (response) {
                commitSetMedications(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<MedicationsState, State>('');

export const dispatchGetMedications = dispatch(actions.actionGetMedications);
