import { IPrescription } from '@/interfaces';
import { PrescriptionsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setPrescriptions(state: PrescriptionsState, payload: IPrescription[]) {
        state.prescriptions = payload;
    },
};

const { commit } = getStoreAccessors<PrescriptionsState, State>('');

export const commitSetPrescriptions = commit(mutations.setPrescriptions);
