import { api } from '@/api';
import { ActionContext } from 'vuex';
import { State } from '../state';
import { PrescriptionsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetPrescriptions } from './mutations';
import { dispatchCheckApiError } from '../main/actions';

type MainContext = ActionContext<PrescriptionsState, State>;

export const actions = {
    async actionGetPrescriptions(context: MainContext, patientId: number) {
        try {
            const response = await api.getPrescriptions(context.rootState.main.token, patientId);
            if (response) {
                commitSetPrescriptions(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
};

const { dispatch } = getStoreAccessors<PrescriptionsState, State>('');

export const dispatchGetPrescriptions = dispatch(actions.actionGetPrescriptions);
