import { PatientsState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    patients: (state: PatientsState) => state.patients,
    patient: (state: PatientsState) => (patientId: number) => {
        const items = state.patients.filter((patient) => patient.id === patientId);
        if (items.length > 0) {
            return { ...items[0] };
        }
    },
};

const { read } = getStoreAccessors<PatientsState, State>('');

export const readPatients = read(getters.patients);
export const readPatient = read(getters.patient);
